import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Jumbotron from 'react-bootstrap/Jumbotron'


export default () => (
  <Layout>
    <Jumbotron className="bg-lightblue">
      <div class="container">
        <div style={{maxWidth:"600px",margin:"auto"}}>
          <h2><b>Page Not Found</b></h2>
          <p className="mt-4">
           Oops! The page your are looking for has been removed or relocated.
          </p>
          <Link to="/">Go Back</Link>
        </div>
      </div>
    </Jumbotron>
  </Layout>
)
